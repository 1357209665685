import { mapActions } from "vuex";
import moment from 'moment';
import 'moment-timezone';
export default {
    computed: { },
    methods: {
        ...mapActions(["genericAPIPOSTRequest","UpdateCurrentUrl","fetchInvitadeUserPermissionList","UpdateBodyScrollRole","updateWindowStatus","UpdateUserOnlineOfflineStatus","UpdateUserSessionStatus"]),
        ...mapActions("global",["UpdateRequestRoomData","UpdateInvitationRequestRoomData","UpdateMessageRequestData"]),

        // this fun is for to get current switched or active user type id
        getCurrentUserIdRequest() {
          return [this.$store.state.currentUserData.first_name+'-'+this.$store.state.currentUserData.last_name+'-'+this.$store.state.currentUserData.uuid,'miniProfileUrn'+this.$store.state.currentUserData.uuid];
        }, 
        // this fun is for to get login user ids
        getLoginUserIdRequest() {
          return ['login-user-'+this.$store.state.loginUserUuid,'miniProfileUrn'+this.$store.state.loginUserUuid];
        }, 
        async getOwnerDetail(){
            var res = await this.genericAPIPOSTRequest({
              requestUrl: "getOwnerDetail",
              params: {
                data:this.getCurrentUserIdRequest(),
                type:'settings',
              },
            });
            this.$store.commit("setcurrentUserOwnerData", res);
            return res;
        },
        consolelog(para1='',para2=''){
            console.log(para1,para2)
        },
        changeAmountvalue(data) {
          let y = new String(data);
          // if(y.length >= 4 || y.length < 7){
          //     return 'k';
          // }else if (y.length >= 7 || y.length <10) {
          //     return 'm'
          // }else if (y.length >= 10 || y.length <=10) {
          //     return 'b'
          // }
          return Math.abs(Number(y)) >= 1.0e+9

          ? Math.abs(Number(y)) / 1.0e+9 + "B"
          // Six Zeroes for Millions 
          : Math.abs(Number(y)) >= 1.0e+6

          ? Math.abs(Number(y)) / 1.0e+6 + "M"
          // Three Zeroes for Thousands
          : Math.abs(Number(y)) >= 1.0e+3

          ? Math.abs(Number(y)) / 1.0e+3 + "K"

          : Math.abs(Number(y));
        },
        _proficiency: function(user) {
          if (user) {
            if (user == 1) {
                return 'Basic';
            } else if (user == 2) {
                return 'Conversational';
            } 
            else if (user == 3) {
                return 'Fluent';
            } 
            else if (user == 4) {
                return 'Native or Bilingual';
            } 
          }
        },
        getHtmlContent(text) {
          if (!text) return "";
            let spaces = this.nl2br(text);
          return spaces;
        },  
        nl2br(str) {
          return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
        },
        // this fun is for to check client user have permission
        isPermissionForClient(type,url=null){
            if(this.$store.state.currentUserData.permission=='' ||this.$store.state.currentUserData.permission== undefined){
                return true;
            }
          //   if(type=='message'){
          //       if(this.$store.state.currentUserData.permission.admin=='1' 
          //       && this.$store.state.currentUserData.permission.hiring=='1' || this.$store.state.currentUserData.permission.chat_with == '1'){
          //         return false;
          //       } else if(this.$store.state.currentUserData.permission.admin > '1' 
          //       || this.$store.state.currentUserData.permission.hiring > '1' && this.$store.state.currentUserData.permission.chat_with > '1'){
          //          return true;   
          //       } 
          //  }
           else if(type=='transactionHistory'){
            if(this.$store.state.currentUserData.permission.admin > 1){
               return true;   
            }  
           }else if(type=='jobs'){
            if(this.$store.state.currentUserData.permission.admin=='1' 
            || this.$store.state.currentUserData.permission.hiring=='1'){
              return false;
            }else if(this.$store.state.currentUserData.permission.hiring > '1'){
               return true;   
            }   
           }
           else if(type=='all-contract'){
            if(this.$store.state.currentUserData.permission.hiring == '3'){
               return true;   
            }   
           }
           else if(type=='my-jobs'){
            if(this.$store.state.currentUserData.permission.hiring > 1){
               return true; 
                 
            }
           }
           else if(type=='all-job-post'){
            if(this.$store.state.currentUserData.permission.hiring > 1){
               return true;   
            }   
           }
           else if(type=='post-job'){
            if(this.$store.state.currentUserData.permission.hiring > 1){
               return true;   
            }   
           }
           else if(type=='talent'){
            if(this.$store.state.currentUserData.permission.hiring > 1){
               return true;   
            }   
           }
           else if(type=='invite-job-btn'){
            if(this.$store.state.currentUserData.permission.hiring > 1){
               return true;   
            }   
           }
           else if(type=='hire-btn'){
            if(this.$store.state.currentUserData.permission.hiring == 3){
               return true;   
            }   
           }
           else if(type=='submit-proposal-btn'){
            if(this.$store.state.currentUserData.permission.hiring > '1'){
               return true;   
            }   
           }
           else if(type=='offers-page'){
            if(this.$store.state.currentUserData.permission.hiring == 3){
               return true;   
            }   
           }
           else if(type=='hired-page'){
            if(this.$store.state.currentUserData.permission.hiring == 3){
               return true;   
            }   
           }
           else if(type=='BillingPayments'){
            if(this.$store.state.currentUserData.permission.admin > 1){
               return true;   
            } 
           }
           else if(type=='taxInformation'){
            if(this.$store.state.currentUserData.permission.admin > 1){
               return true;   
            } 
           }
           else if(type=='memberPermission'){
            if(this.$store.state.currentUserData.permission.admin == 3){
                return true;   
             } 
           }

           else if(type=='companyEdit'){
             if(this.$store.state.currentUserData.permission.admin=='3'){
                return true;   
             }
           } 
           else if(type=='client-saved'){
            if(this.$store.state.currentUserData.permission.admin=='3'){
               return true;   
            }
          }
          else if(type=='client-Invited'){
            if(this.$store.state.currentUserData.permission.admin=='3'){
               return true;   
            }
          }

          if(url!=''){
            // this.$router.push(url);
          }
          return false;
        },
      //   isPermissionForClient(type){
      //     if(this.$store.state.currentUserData.permission=='' ||this.$store.state.currentUserData.permission== undefined){
      //         return true;
      //     }
      //     if(type=='message'){
      //         if(this.$store.state.currentUserData.permission.admin=='1' 
      //         && this.$store.state.currentUserData.permission.hiring=='1' || this.$store.state.currentUserData.permission.chat_with == '1'){
      //           return false;
      //         } else if(this.$store.state.currentUserData.permission.admin > '1' 
      //         || this.$store.state.currentUserData.permission.hiring > '1' && this.$store.state.currentUserData.permission.chat_with > '1'){
      //            return true;   
      //         } 
      //    }
      //    else if(type=='reports'){
      //     if(this.$store.state.currentUserData.permission.admin=='1' 
      //     && this.$store.state.currentUserData.permission.hiring=='1'){
      //       return false;
      //     } else if(this.$store.state.currentUserData.permission.admin > '1' 
      //     || this.$store.state.currentUserData.permission.hiring > '1'){
      //        return true;   
      //     }  
      //    }else if(type=='jobs'){
      //     if(this.$store.state.currentUserData.permission.admin=='1' 
      //     || this.$store.state.currentUserData.permission.hiring=='1'){
      //       return false;
      //     }else if(this.$store.state.currentUserData.permission.hiring > '1'){
      //        return true;   
      //     }   
      //    }
      //    else if(type=='all-contract'){
      //     if(this.$store.state.currentUserData.permission.hiring == '3'){
      //        return true;   
      //     }   
      //    }
      //    else if(type=='myinfo'){
      //     return true; 
      //     // if(this.$store.state.currentUserData.permission.admin=='1' 
      //     // && this.$store.state.currentUserData.permission.hiring=='1'){
      //     //   return false;
      //     // } else if(this.$store.state.currentUserData.permission.admin > '1' 
      //     // || this.$store.state.currentUserData.permission.hiring > '1'){
      //     //    return true;   
      //     // }  
      //    }
      //    else if(type=='BillingPayments'){
      //     if(this.$store.state.currentUserData.permission.admin=='1' 
      //     && this.$store.state.currentUserData.permission.hiring=='1'){
      //       return false;
      //     } else if(this.$store.state.currentUserData.permission.admin > '1' 
      //     || this.$store.state.currentUserData.permission.hiring > '1'){
      //        return true;   
      //     } 
      //    }
      //    else if(type=='passwordSecurity'){
      //     return true;
      //     // if(this.$store.state.currentUserData.permission.admin=='1' 
      //     // && this.$store.state.currentUserData.permission.hiring=='1'){
      //     //   return false;
      //     // } else if(this.$store.state.currentUserData.permission.admin > '1' 
      //     // || this.$store.state.currentUserData.permission.hiring > '1'){
      //     //    return true;   
      //     // }  
      //    }
      //    else if(type=='notificationSetting'){
      //     return true;
      //     // if(this.$store.state.currentUserData.permission.admin=='1' 
      //     // && this.$store.state.currentUserData.permission.hiring=='1'){
      //     //   return false;
      //     // } else if(this.$store.state.currentUserData.permission.admin > '1' 
      //     // || this.$store.state.currentUserData.permission.hiring > '1'){
      //     //    return true;   
      //     // } 
      //    }
      //    else if(type=='taxInformation'){
      //     if(this.$store.state.currentUserData.permission.admin=='1' 
      //     && this.$store.state.currentUserData.permission.hiring=='1'){
      //       return false;
      //     } else if(this.$store.state.currentUserData.permission.admin > '1' 
      //     || this.$store.state.currentUserData.permission.hiring > '1'){
      //        return true;   
      //     } 
      //    }
      //    else if(type=='memberPermission'){
      //     if((this.$store.state.currentUserData.permission.admin=='1' ||this.$store.state.currentUserData.permission.admin=='2') 
      //       && (this.$store.state.currentUserData.permission.hiring=='1' || this.$store.state.currentUserData.permission.hiring=='2')){
      //          return false;
      //      } else if(this.$store.state.currentUserData.permission.admin=='3' 
      //      || this.$store.state.currentUserData.permission.hiring=='3'){
      //         return true;   
      //      } 
      //    }
      //    else if(type=='companyEdit'){
      //      if(this.$store.state.currentUserData.permission.admin=='3'){
      //         return true;   
      //      }
      //    } 
      //    else if(type=='client-saved'){
      //     if(this.$store.state.currentUserData.permission.admin=='3'){
      //        return true;   
      //     }
      //   }
      //   else if(type=='client-Invited'){
      //     if(this.$store.state.currentUserData.permission.admin=='3'){
      //        return true;   
      //     }
      //   } 
        
      //    return false;
      // },
        // this fun is for to check user have permission for requested user
        isPermissionForAgency(type){
          if(this.$store.state.currentUserData.permission=='' ||this.$store.state.currentUserData.permission== undefined){
            return true;
          }
          else if(type=='message'){
            if(this.$store.state.currentUserData.permission.admin=='1' 
            && this.$store.state.currentUserData.permission.business=='1' &&  this.$store.state.currentUserData.permission.agency_contract == '1' || this.$store.state.currentUserData.permission.chat_with == '1'){
              return false;
            } else if(this.$store.state.currentUserData.permission.admin > '1' 
            || this.$store.state.currentUserData.permission.business > '1' || this.$store.state.currentUserData.permission.agency_contract > '1' || this.$store.state.currentUserData.permission.chat_with > '1'){
                return true;   
            } 
          }
          else if(type=='agencySetting'){
            if(this.$store.state.currentUserData.permission.admin == '3'){
              return true;   
            } 
          }
          else if(type=='memberPermission'){
            if(this.$store.state.currentUserData.permission.admin == '3'){
              return true;   
            } 
          }
          else if(type=='taxInformation'){
            if(this.$store.state.currentUserData.permission.admin > '1'){
              return true;   
            } 
            // if(this.$store.state.currentUserData.permission.admin=='1' && this.$store.state.currentUserData.permission.business=='1'  && this.$store.state.currentUserData.permission.agency_contract=='1'){
            //   return false;
            // } else if(this.$store.state.currentUserData.permission.admin > '1'){
            //   return true;   
            // } 
          }
          else if(type=='transactionHistory'){
            if(this.$store.state.currentUserData.permission.admin > '1'){
              return true;   
            } 
            // if(this.$store.state.currentUserData.permission.admin=='1' && this.$store.state.currentUserData.permission.business=='1'  && this.$store.state.currentUserData.permission.agency_contract=='1'){
            //   return false;
            // } else if(this.$store.state.currentUserData.permission.admin > '1'){
            //   return true;   
            // } 
          }
          else if(type=='contactInfo'){
          if(this.$store.state.currentUserData.permission.admin=='1' 
          && this.$store.state.currentUserData.permission.business=='1'){
            return false;
          } else if(this.$store.state.currentUserData.permission.admin > '1' 
          || this.$store.state.currentUserData.permission.business > '1'){
              return true;   
          } 
          }
          else if(type=='myprofile'){
          if(this.$store.state.currentUserData.permission.admin=='1' 
          && this.$store.state.currentUserData.permission.business=='1'){
            return false;
          } else if(this.$store.state.currentUserData.permission.admin > '1' 
          || this.$store.state.currentUserData.permission.business > '1'){
              return true;   
          } 
          }
          else if(type=='profileSetting'){
          if(this.$store.state.currentUserData.permission.admin=='1' 
          && this.$store.state.currentUserData.permission.business=='1'){
            return false;
          } else if(this.$store.state.currentUserData.permission.admin > '1' 
          || this.$store.state.currentUserData.permission.business > '1'){
              return true;   
          } 
          }
          else if(type=='getPaid'){
            if(this.$store.state.currentUserData.permission.admin > 1){
              return true;
            } 
            // if(this.$store.state.currentUserData.permission.admin=='1' && this.$store.state.currentUserData.permission.business=='1' && this.$store.state.currentUserData.permission.agency_contract=='1'){
            //   return false;
            // } else if(this.$store.state.currentUserData.permission.admin > '1' || this.$store.state.currentUserData.permission.business > '1'){
            //   return true;   
            // } 
          }
          else if(type=='passwordSecurity'){
          return true;
          // if(this.$store.state.currentUserData.permission.admin=='1' 
          // && this.$store.state.currentUserData.permission.business=='1'){
          //   return false;
          // } else if(this.$store.state.currentUserData.permission.admi  n > '1' 
          // || this.$store.state.currentUserData.permission.business > '1'){
          //    return true;   
          // }  
          }
          else if(type=='notificationSetting'){
            return true;
          // if(this.$store.state.currentUserData.permission.admin=='1' 
          // && this.$store.state.currentUserData.permission.business=='1'){
          //   return false;
          // } else if(this.$store.state.currentUserData.permission.admin > '1' 
          // || this.$store.state.currentUserData.permission.business > '1'){
          //    return true;   
          // } 
          }
          else if(type=='find-job'){
            if(this.$store.state.currentUserData.permission.business > '1'){
                return true;   
            } 
          } 
          else if(type=='all-Contracts'){
            if(this.$store.state.currentUserData.permission.business > '1'){
              return true;   
            } 
          }
          else if(type=='apply-job'){
            if(this.$store.state.currentUserData.permission.business > '1'){
              return true;   
            } 
          }
           return false;

        },
        // isPermissionForAgency(type){
            
        //   if(this.$store.state.currentUserData.permission=='' ||this.$store.state.currentUserData.permission== undefined){
        //     return true;
        //   }
        //   else if(type=='message'){
              
        //         if(this.$store.state.currentUserData.permission.admin=='1' 
        //         && this.$store.state.currentUserData.permission.business=='1' &&  this.$store.state.currentUserData.permission.agency_contract == '1' || this.$store.state.currentUserData.permission.chat_with == '1'){
        //           return false;
        //         } else if(this.$store.state.currentUserData.permission.admin > '1' 
        //         || this.$store.state.currentUserData.permission.business > '1' || this.$store.state.currentUserData.permission.agency_contract > '1' || this.$store.state.currentUserData.permission.chat_with > '1'){
        //            return true;   
        //         } 
        //   }
        //   else if(type=='agencySetting'){
        //     if(this.$store.state.currentUserData.permission.admin=='3'){
        //       return true;   
        //     } 
        //     if(this.$store.state.currentUserData.permission.admin=='3'){
        //       return true;   
        //     } 
        //   }
        //   else if(type=='memberPermission'){
        //     if(this.$store.state.currentUserData.permission.admin=='3'){
        //       return true;   
        //     } 
        //   }
        //   else if(type=='taxInformation'){
        //     if(this.$store.state.currentUserData.permission.admin > '1'){
        //       return true;   
        //     } 
        //     // if(this.$store.state.currentUserData.permission.admin=='1' && this.$store.state.currentUserData.permission.business=='1'  && this.$store.state.currentUserData.permission.agency_contract=='1'){
        //     //   return false;
        //     // } else if(this.$store.state.currentUserData.permission.admin > '1'){
        //     //   return true;   
        //     // } 
        //   }
        //   else if(type=='transactionHistory'){
        //     if(this.$store.state.currentUserData.permission.admin > '1'){
        //       return true;   
        //     } 
        //     // if(this.$store.state.currentUserData.permission.admin=='1' && this.$store.state.currentUserData.permission.business=='1'  && this.$store.state.currentUserData.permission.agency_contract=='1'){
        //     //   return false;
        //     // } else if(this.$store.state.currentUserData.permission.admin > '1'){
        //     //   return true;   
        //     // } 
        //   }
        //   else if(type=='contactInfo'){
        //   if(this.$store.state.currentUserData.permission.admin=='1' 
        //   && this.$store.state.currentUserData.permission.business=='1'){
        //     return false;
        //   } else if(this.$store.state.currentUserData.permission.admin > '1' 
        //   || this.$store.state.currentUserData.permission.business > '1'){
        //       return true;   
        //   } 
        //   }
        //   else if(type=='myprofile'){
        //   if(this.$store.state.currentUserData.permission.admin=='1' 
        //   && this.$store.state.currentUserData.permission.business=='1'){
        //     return false;
        //   } else if(this.$store.state.currentUserData.permission.admin > '1' 
        //   || this.$store.state.currentUserData.permission.business > '1'){
        //       return true;   
        //   } 
        //   }
        //   else if(type=='profileSetting'){
        //   if(this.$store.state.currentUserData.permission.admin=='1' 
        //   && this.$store.state.currentUserData.permission.business=='1'){
        //     return false;
        //   } else if(this.$store.state.currentUserData.permission.admin > '1' 
        //   || this.$store.state.currentUserData.permission.business > '1'){
        //       return true;   
        //   } 
        //   }
        //   else if(type=='getPaid'){
        //     if(this.$store.state.currentUserData.permission.admin > '1'){
        //       return true;   
        //     } 
        //     // if(this.$store.state.currentUserData.permission.admin=='1' && this.$store.state.currentUserData.permission.business=='1' && this.$store.state.currentUserData.permission.agency_contract=='1'){
        //     //   return false;
        //     // } else if(this.$store.state.currentUserData.permission.admin > '1' || this.$store.state.currentUserData.permission.business > '1'){
        //     //   return true;   
        //     // } 
        //   }
        //   else if(type=='passwordSecurity'){
        //   return true;
        //   // if(this.$store.state.currentUserData.permission.admin=='1' 
        //   // && this.$store.state.currentUserData.permission.business=='1'){
        //   //   return false;
        //   // } else if(this.$store.state.currentUserData.permission.admi  n > '1' 
        //   // || this.$store.state.currentUserData.permission.business > '1'){
        //   //    return true;   
        //   // }  
        //   }
        //   else if(type=='notificationSetting'){
        //     return true;
        //   // if(this.$store.state.currentUserData.permission.admin=='1' 
        //   // && this.$store.state.currentUserData.permission.business=='1'){
        //   //   return false;
        //   // } else if(this.$store.state.currentUserData.permission.admin > '1' 
        //   // || this.$store.state.currentUserData.permission.business > '1'){
        //   //    return true;   
        //   // } 
        //   }
        //   else if(type=='find-job'){
        //     if(this.$store.state.currentUserData.permission.business > '1'){
        //         return true;   
        //     } 
        //   } 
        //   else if(type=='agencySetting'){
        //     if(this.$store.state.currentUserData.permission.admin=='3'){
        //       return true;   
        //     } 
        //   }
        //    return false;

        // },
        timeDiffrenceInminutes(startTimes,endTimes){
          var startTime = new Date(startTimes); 
          var endTime = new Date(endTimes);
          var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
          var resultInMinutes = Math.round(difference / 60000);
          return resultInMinutes;
        },
        // this fun is for to encode the id
        encodedid(id){
          return btoa(id);
        },
        // this fun is for to decode the id
        decodeId(id){
          return atob(id);
        },

        //this fun is for to show header after
        checkheaderAfterAuth(){
          if(this.$route.name == 'Login' ){
            return true;
          }else if (this.$route.name == 'BasicInfo') {
            return true;
          }else if (this.$route.name == 'ForgotPassword') {
            return true;
          } else {
            return false; 
          }
        },
        // this fun is for to encode the id
        shortName(first,last=null){
          var name = first+' ';
          if(last!=null){
            name += last.slice(0,1);
          }
          return name;
        },
        isNumber(event) {
          if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault();
        },
        // this fun is for to delete apply job application
        async deleteApplyJob(user_id,job_id){
          await this.genericAPIPOSTRequest({
            requestUrl      : "deleteApplyJob",
            params          : {
                'user_id'         : user_id,
                'jobs_id'         : job_id,
                // 'agency_id'       : (this.userDetails.exclusiveAssociated !='')?this.userDetails.exclusiveAssociated.login_master_id:0,
              }
          });
          return true;
        },
        async permissionList(){
          this.fetchInvitadeUserPermissionList()
        },
        //this fun is for to update personal chat note data
        async addPersonalNote(data){
          var res = await this.genericAPIPOSTRequest({
            requestUrl      : (data.chat_note_id == '')?"addChatNote":"updateChatNote",
            params          : {
                'user_id'         : data.user_id,
                'chat_room_id'    : data.chat_room_id,
                'note'            : data.note,
                'chat_note_id'    : data.chat_note_id
              }
          });
          if(res){
            if(data.chat_note_id == ''){
              this.$toastr.s('Note Added')
            }else{
              this.$toastr.s('Note Updated')
            }
            return true;
          }else{
            this.$toastr.e('Note not updated')
            return false;
          }
        },
        // this fun is for to change time according to local time
        convertToLocalTime(data){
          try {
            const serverTimeZone = moment.tz(data, 'YYYY-MM-DD HH:mm:ss', this.$store.state.serverTimeZone);

            // Convert the date to the Asia/Kolkata timezone
            const currentUserTimeZone = serverTimeZone.clone().tz(this.$store.state.userTimeZone);

            // Format the date in the desired format
            var convertedDate = currentUserTimeZone.format('HH:mm:ss');
            return convertedDate;
          } catch (error) {
            console.error('Invalid date format:', error);
          }
        },
        // this fun is for to download File
        async downloadFile(data){
          window.location.href = this.$store.state.baseUrlImg+'/home/download?img='+ data;``
          // window.location.href = data;
        },
        async getsCompanyCount() {
          var res = await this.genericAPIPOSTRequest({
            requestUrl: "company/getCompanyCount",
            params: { data: this.getLoginUserIdRequest() },
          });
          this.$store.dispatch("listAccount", res);
        },
        async updateHtmlClass(){
          this.UpdateBodyScrollRole({params: !this.$store.state.stopBodyScroll});
        },
        async getApiData(url,param){
          return  await this.genericAPIPOSTRequest({
            requestUrl: url,
            params: param,
          });
        }, 
        //this fun is for date currnt date with given formate or
        dateHelper(formate = '',addDays='',requestedDate =''){
          var currentDate;
          if(requestedDate !=''){
            currentDate = new Date(requestedDate);
          }else{
            currentDate = new Date();
          }
          // Add days to the current date
          if(addDays > 0){
            currentDate.setDate(currentDate.getDate() + addDays);
          }
          // Format the date as "M d, Y"
          if(formate!=''){
            if(formate == "M d, Y"){
              var options = { year: 'numeric', month: 'long', day: 'numeric' };
            }
            currentDate = currentDate.toLocaleDateString('en-US', options);
          }
          return currentDate;
        },

        // this fun is for to get logout from current session
        logout(){
          this.updateOnlineOffline((this.$store.state.userOnlineStatus == 1 || this.$store.state.userOnlineStatus == 0) ? '0' : this.$store.state.userOnlineStatus);
          // return false;
          localStorage.setItem("UserSessionStatus", 'logout');
          this.UpdateUserSessionStatus({params : ''});
          this.UpdateUserSessionStatus({params : 'logout'});
          this.UpdateUserOnlineOfflineStatus({params : 0});
          this.UpdateCurrentUrl({params:[]});
          this.UpdateRequestRoomData({params:{'user_id':'','status':1,'userType': '','title': '','sort': 'DESC'}});
          this.UpdateInvitationRequestRoomData({params:{'user_id':'','member_permission_id': '','title': '','sort': 'DESC'}});
          this.UpdateMessageRequestData({params:{'chat_room_id': '','start_limit': 0,'end_limit': 50,'cycle': '','lastId': '','firstId': '',}});
          this.$store.dispatch("currentUserData", '');
          this.$store.dispatch("accountType", '');
          this.$store.state.ExperienceLevelData           = [];
          this.$store.state.LanguageData                  = [];
          this.$store.state.certificationList             = [];
          this.$store.state.currentUserOwnerData          = [];
          this.$store.state.listAccount                   = { company: '',qp_user: '',user: '',};
          this.$store.state.global.mChatData              = [];
          this.$store.state.global.roomData               = [];
          this.$store.state.loginUserUuid                 = '';
          this.$store.state.profileImg                    = '';
          this.$store.state.swiftKey                      = '';
          this.$store.state.talentData                    = [];
          localStorage.removeItem("token");
          localStorage.removeItem("accountType");
          localStorage.removeItem("loginUserData");
          localStorage.removeItem("loginUserUuid");
          this.$router.push("/login").catch(()=>{});
          this.$toastr.s("Logout successfully.");
        },
        
        // this fun is for to check window resolution
        async checkwindowResolution(){
          setInterval(() => {
            if(window.innerWidth < 816){
              this.updateWindowStatus({params: true});
            }else{
              this.updateWindowStatus({params: false});
            }
          }, 1000);
          return true;
        },

        // this fun is for to update user online offline status
        async updateOnlineOffline(_v = null){
          // console.log('this.$store.state.userOnlineStatus',this.$store.state.userOnlineStatus,_v);
          // return false;
          var res = await this.genericAPIPOSTRequest({
            requestUrl: "updateUserOnlineStatus",
            params: { 'online_status': (_v == null ) ? this.$store.state.userOnlineStatus : _v },
          });
          if(res){
            var userData            = this.$store.state.currentUserData;
            userData.online_status  = res.online_status;
            if(res.online_status == 2){
              userData.online_status = 2;
              userData.online_timestamp = res.online_timestamp;
            }else if(res.online_status == 1){
              userData.online_status = 1;
              userData.online_timestamp = res.online_timestamp;
            }
            this.$store.dispatch("currentUserData",userData);
            this.UpdateUserOnlineOfflineStatus({params : userData.online_status});
          }
          return res;

        },
        checkUserOnlineOrOffline(data){
          console.log('data',data.first_name,'/',data.online_timestamp,'/',data.online_status)
          // online_timestamp:"2024-07-23 09:19:53"
          if(data.online_status == 1 && data.online_timestamp !=''){
            const serverTimeZone = moment.tz(data.online_timestamp, 'YYYY-MM-DD HH:mm:ss', this.$store.state.serverTimeZone);
            // Convert the date to the Asia/Kolkata timezone
            const currentUserTimeZone = serverTimeZone.clone().tz(this.$store.state.userTimeZone);
            // Format the date in the desired format
            var convertedDate = currentUserTimeZone.format('YYYY-MM-DD HH:mm:ss');
            var startDate = new Date(convertedDate)
            var endDate = new Date(moment().format("YYYY-MM-DD HH:mm:ss"))
            const diff = endDate.getTime() - startDate.getTime();
            var timeDiff = Math.round(diff / 60000);
            // console.log(startDate,endDate);
            console.log('timeDiff',timeDiff);
            if(parseInt(timeDiff) > parseInt(30)){
              console.log('if-false')
              return false;
            }else{
              console.log('else-true')
              return true;
            }
          }
          return false;
        },
        // this fun is for to give two dates difference in minuites
        getMinutesBetweenDates(startDate,endDate) {
          const diff = endDate.getTime() - startDate.getTime();
      
          return Math.round(diff / 60000);
        }
      
    }  
}